.promo {
    display: flex;
    flex-direction: column;
    gap: 40px;
    margin-bottom: 80px;
}

.promo__title {
    margin: 0;
    font-size: 52px;
    font-weight: 700;
    color: var(--basic-black);
}

.promo-slider__item {
    border-radius: 40px;
    overflow: hidden;
}

.promo-slider__item-img {
    width: 100%;
    height: 430px;
    display: block;
    object-fit: cover;
}

@media (max-width: 600px) {
    .promo-slider__item-img {
        height: 350px;
    }
}

@media (max-width: 400px) {
    .promo-slider__item-img {
        height: 200px;
    }
}
