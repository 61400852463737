.promo {
  flex-direction: column;
  gap: 40px;
  margin-bottom: 80px;
  display: flex;
}

.promo__title {
  color: var(--basic-black);
  margin: 0;
  font-size: 52px;
  font-weight: 700;
}

.promo-slider__item {
  border-radius: 40px;
  overflow: hidden;
}

.promo-slider__item-img {
  object-fit: cover;
  width: 100%;
  height: 430px;
  display: block;
}

@media (width <= 600px) {
  .promo-slider__item-img {
    height: 350px;
  }
}

@media (width <= 400px) {
  .promo-slider__item-img {
    height: 200px;
  }
}
/*# sourceMappingURL=index.cf39de3e.css.map */
